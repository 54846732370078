.breadcrumb {
  &:not([class*='breadcrumb-']) {
    .breadcrumb-item + .breadcrumb-item {
      &:before {
        content: ' ';
        background-image: url(str-replace(str-replace($chevron-right, 'currentColor', $body-color), '#', '%23'));
        background-repeat: no-repeat;
        background-position: center;
        color: $body-color;
        margin-right: $breadcrumb-item-padding-x;
        background-size: 14px;
      }
    }
  }
  .breadcrumb-item + .breadcrumb-item {
    &:before {
      height: 20px;
    }
  }
}

/* Component Specific */
.breadcrumb-slash {
  &.breadcrumb {
    .breadcrumb-item + .breadcrumb-item:before {
      content: '/';
    }
  }
}
.breadcrumb-dots {
  &.breadcrumb {
    .breadcrumb-item + .breadcrumb-item:before {
      content: '.';
      position: relative;
      top: -4px;
    }
  }
}
.breadcrumb-dashes {
  &.breadcrumb {
    .breadcrumb-item + .breadcrumb-item:before {
      content: '-';
    }
  }
}
.breadcrumb-pipes {
  &.breadcrumb {
    .breadcrumb-item + .breadcrumb-item:before {
      content: '|';
    }
  }
}
.breadcrumb-chevron {
  &.breadcrumb {
    .breadcrumb-item + .breadcrumb-item:before {
      content: ' ';
      background-image: url(str-replace(str-replace($chevron-right, 'currentColor', $body-color), '#', '%23'));
      background-repeat: no-repeat;
      background-position: center;
      color: $body-color;
      margin-right: $breadcrumb-item-padding-x;
      background-size: 14px;
    }
  }
}
// padding left for header area breadcrumbs
.content-header .breadcrumb {
  padding-left: 1rem;
}

@media (max-width: 648px) {
   .breadcrumb-wrapper{
      overflow-x: scroll;
   }
  .content-header .breadcrumb {
    padding-left: 0;
    flex-wrap: nowrap;

    > .breadcrumb-item {
      padding-left: 1rem;
      padding-right: 0.5rem;
      white-space: nowrap;
      position: relative;

      &:before{
         margin-top: 1px;
         position: absolute;
         left: 0px;
         top: 0px;
      }

      &:first-child{
         padding-left: 0;
      }
   }
  }
  .breadcrumbs-top {
    .content-header-title {
      display: contents !important;
    }
  }
}
